import bwipjs from 'bwip-js';
import React, { useEffect, useState } from 'react';
import { useWs } from 'src/context/WebsocketContext';
import MessageContainer from './Messages';
import './footer.css';

export default function InteractiveFooter({ questionMap }) {
  const [messageHistory, setMessageHistory] = useState([
    {
      name: 'AWS',
      image: 'aws',
      response: 'Welcome to the AWS Amsterdam Summit!',
    },
    {
      name: 'AWS',
      image: 'aws',
      response: 'Connect using the QR Code to answer questions!',
    },
  ]);
  const [imgUrl, setImgUrl] = useState(null);
  const { addAction, removeAction } = useWs();

  const createQrCodeElement = (website) => {
    const canvas = document.createElement('canvas');
    bwipjs.toCanvas(canvas, {
      bcid: 'qrcode',
      text: website,
      textxalign: 'center',
      barcolor: 'FFFFFF',
      width: 50,
      height: 50,
    });
    setImgUrl(canvas.toDataURL('image/png'));
  };

  useEffect(() => {
    const newUserAction = (newUser) => {
      const message = 'has joined.';
      // Should we just keeep the two last messages? Would that effect the rendering?
      setMessageHistory([
        ...messageHistory,
        { name: newUser.name, image: newUser.image, response: message },
      ]);
    };
    const answerAction = (answer) => {
      const message = `has voted "${answer.answer}" for question "${
        questionMap[answer.questionId]
      }"`;
      setMessageHistory([
        ...messageHistory,
        { name: answer.name, response: message, image: answer.image },
      ]);
    };

    addAction('answer', answerAction);
    addAction('newlyConnected', newUserAction);
    createQrCodeElement(process.env.REACT_APP_DOMAIN);
    return () => {
      removeAction('newlyConnected', newUserAction);
      removeAction('answer', answerAction);
    };
  }, [messageHistory, addAction, questionMap, removeAction]);

  return (
    <div id="interactiveFooter">
      <div id="footerQrCode">
        {imgUrl ? (
          <img style={{ height: '100px' }} id="qrCode" alt="" src={imgUrl} />
        ) : null}
      </div>
      <div id="footerHistory">
        <MessageContainer messages={messageHistory} />
      </div>
      <div id="footerLogo">
        <img style={{ height: '100px' }} id="AWS" alt="" src="AWS.png" />
      </div>
    </div>
  );
}
