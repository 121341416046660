import React, { useEffect, useState } from 'react';
import './dashboard.css';
import InteractiveFooter from 'src/components/Footer';
import ChartsView from 'src/components/ChartsView';
import { useWs } from 'src/context/WebsocketContext';

export default function DashboardPage() {
  const { connectDashboard, removeAction, addAction } = useWs();
  const [charts, setCharts] = useState([]);
  const [questionIdToQuestion, setIdToQuestionMap] = useState({});

  const fetchCharts = () => fetch(`${process.env.REACT_APP_API_DOMAIN}/questions`)
    .then((data) => data.json())
    .then((data) => {
      const map = data.questions.reduce((acc, prev) => {
        acc[prev.questionId] = prev.question;
        return acc;
      }, {});
      setCharts(data.questions);
      setIdToQuestionMap(map);
    });

  useEffect(() => {
    connectDashboard();
    fetchCharts();

    addAction('disconnect', connectDashboard);
    return () => {
      removeAction('disconnect', connectDashboard);
    };
  }, [connectDashboard, removeAction, addAction]);

  return (
    <>
      <div className="titleContainer">
        <h1>Vote with the QR Code for a chance to WIN a Ring Doorbell.</h1>
        <h2>Be on top of your business insights</h2>
      </div>
      <ChartsView charts={charts} />
      <InteractiveFooter questionMap={questionIdToQuestion} />
    </>
  );
}
