import React, { useEffect, useState, useCallback } from 'react';
import './chart.css';
import { useWs } from 'src/context/WebsocketContext';

/**
 * There is a method to do this with a flag that is enabled via the Quicksight
 * Team, but I cannot do that currently with the CDK. And this is, in part, a cdk
 * based project.
 *
 * In the future I would replace this with that method, which seems to send periodic
 * Websocket messages about updates.
 *
 * I do wish there was a way to prompt the iframe to do this update, and keep rendered while it
 * was doing so.
 *
 * What this ends up doing instead is it this. Where we have two charts rendered, and the background chart
 * polling for updates. Then we rotate them around.
 *
 * It's hacky, but it does work currently. And it's likely a method that someone trying to implement this
 * without the business version of Quicksights could accomplish.
 *
 */
export default function Chart({ chart, index }) {
  const INITIAL_RENDER_DELAY_INTERVAL = 5000;
  const UPDATE_RATE = 15000; // Will this break? It will be set to update charts every 10 seconds. This will be better looking in the inteim.
  const [renderedChart, changeRenderedChart] = useState(0);
  const [shown, setShown] = useState(false);
  const [updatePending, setUpdatePending] = useState(false);
  const [timerPending, setTimerPending] = useState(false);

  const { addAction, removeAction } = useWs();

  const flipChartsOnPredicate = useCallback((predicate) => (answer) => {
    if (answer.questionId === chart.questionId) {
      const nextIframe = document.getElementById(
        `${chart.questionId}${renderedChart}`,
      );
      if (nextIframe && predicate()) {
        setTimerPending(true);
        setUpdatePending(false);
        nextIframe.src = '';
        setTimeout(() => { setTimerPending(false); }, UPDATE_RATE);
        setTimeout(() => { nextIframe.src = chart.url; }, 100);
        setTimeout(() => changeRenderedChart(renderedChart === 0 ? 1 : 0), 3000);
        return true;
      }
      return false;
    }

    return true;
  }, [chart, renderedChart]);

  useEffect(() => {
    const flipChartsOnMessage = (ans) => {
      if (!flipChartsOnPredicate(() => timerPending === false)(ans)) {
        console.log(`Set Timer Pending: ${chart.questionId}`);
        setTimerPending(true);
      }
    };

    const flipChartsOnUpdatePending = flipChartsOnPredicate(() => updatePending === true);
    const onAnswer = (ans) => flipChartsOnMessage(ans) || setUpdatePending(true);
    addAction('answer', onAnswer);

    let interval;
    if (shown) {
      interval = setTimeout(() => {
        flipChartsOnUpdatePending({ questionId: chart.questionId });
        // Separate the rerendering on the charts by 5 seconds to mitigate 429s.
      }, UPDATE_RATE);
    }
    const show = setTimeout(() => {
      setShown(true);
    }, INITIAL_RENDER_DELAY_INTERVAL * index);

    return () => {
      if (interval) {
        clearTimeout(interval);
      }

      clearTimeout(show);

      removeAction('answer', onAnswer);
    };
  }, [renderedChart, shown, index, chart.questionId, chart.url, addAction, removeAction, timerPending, updatePending, flipChartsOnPredicate]);

  const reloadChart = () => flipChartsOnPredicate(() => true)(chart);

  return (
    <div id="frame">
      <button className="reloadButton" onClick={reloadChart} type="button">🔄</button>
      <div className="stack">
        {shown && (
          <iframe
            title={`${chart.questionId}1`}
            id={`${chart.questionId}1`}
            hidden={false}
            style={{ opacity: renderedChart === 1 ? 0 : 1, pointerEvents: renderedChart === 1 ? 'none' : 'auto' }}
            width="1100"
            height="680"
            src={chart.url}
          />
        )}
      </div>
      <div className="stack">
        {shown && (
          <iframe
            title={`${chart.questionId}0`}
            id={`${chart.questionId}0`}
            hidden={false}
            style={{ opacity: renderedChart === 1 ? 1 : 0, pointerEvents: renderedChart === 1 ? 'auto' : 'none' }}
            width="1100"
            height="680"
            src={chart.url}
          />
        )}
      </div>
    </div>
  );
}
