import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import 'flickity/css/flickity.css';
import './questionCard.css';
import {
  Card,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
} from '@mui/material';

export default function QuestionCard({ question, username }) {
  const [answer, setAnswer] = useState(question.answers[0]);
  const navigate = useNavigate();

  const [savedAnswerData, setSavedAnswerData] = useState(
    JSON.parse(localStorage.getItem(question.questionId)),
  );

  const changedAnswer = (event) => {
    const selected = event.target.value;
    setAnswer(selected);
  };

  const openDashboard = () => {
    const url = encodeURIComponent(question.url);
    return navigate(`/dashboard/${url}`);
  };

  const sendAnswer = () => {
    const answerData = {
      questionId: question.questionId,
      answer,
      user: username,
    };
    return fetch(`${process.env.REACT_APP_API_DOMAIN}/answers`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(answerData),
    })
      .then((data) => data.json())
      .then(() => {
        const stringedData = JSON.stringify(answerData);
        setSavedAnswerData(answerData);
        localStorage.setItem(question.questionId, stringedData);
      });
  };

  return (
    <Card variant="outlined" className="questionCard">
      <h1>{question.question}</h1>
      <FormControl className="questionAnswer">
        <FormLabel>
          Your answer:
          {' '}
          {savedAnswerData && savedAnswerData.answer}
        </FormLabel>
        <RadioGroup
          row
          onChange={changedAnswer}
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          defaultValue={question.answers[0]}
        >
          {question.answers.map((it) => (
            <FormControlLabel
              key={`${question.questionId}${it}`}
              value={it}
              control={<Radio disabled={savedAnswerData} />}
              label={it}
            />
          ))}
        </RadioGroup>
        <div className="buttonGroup">
          <Button
            onClick={sendAnswer}
            disabled={savedAnswerData}
            className="submitButton"
          >
            Submit
          </Button>
          <Button onClick={openDashboard} className="dashboardButton">
            View Dashboard
          </Button>
        </div>
      </FormControl>
    </Card>
  );
}
