import * as React from 'react';
import {
  Routes,
  Route,
  Outlet,
  BrowserRouter as Router,
} from 'react-router-dom';
import DashboardPage from 'src/pages/Dashboard';
import { WebsocketProvider } from 'src/context/WebsocketContext';
import QuestionsPage from 'src/pages/Questions';
import WinnerSelectionPage from 'src/pages/Winner';
import ViewDashboard from './pages/ViewDashboard';

export default function App() {
  return (
    <div className="container">
      <WebsocketProvider>
        <Router>
          <Routes>
            <Route path="">
              <Route index element={<QuestionsPage />} />
              {/* Security by Obfuscation. Someone getting to the dashboard would break the flow */}
              <Route path="/a8718c46-0b61" element={<DashboardPage />} />
              <Route path="/a8718c46-0b61/winner" element={<WinnerSelectionPage />} />
              <Route path="/dashboard/:url" element={<ViewDashboard />} />
            </Route>
          </Routes>
        </Router>

        <Outlet />
      </WebsocketProvider>
    </div>
  );
}
