import React, { useEffect, useRef } from 'react';
import Flickity from 'react-flickity-component';
import Chart from './Chart';
import 'flickity/css/flickity.css';
import './chartsview.css';

export default function ChartsView({ charts }) {
  const flickity = useRef();

  const renderCharts = (chartsIt) => chartsIt.map((chart, idx) => (
    <Chart key={`${chart.questionId}-chart`} index={idx} chart={chart} />
  ));

  useEffect(() => {
    if (flickity.current) {
      flickity.current.options.autoPlay = 10000;
    }
  }, []);

  return (
    <div className="mainDashboard">
      <div className="chartViewContainer">
        <Flickity
          flickityRef={(carouselRef) => {
            if (flickity) flickity.current = carouselRef;
          }}
          className="chartsContainer"
          options={{
            pageDots: false, initialIndex: 1, wrapAround: true, autoPlay: 10000,
          }}
        >
          {renderCharts(charts)}
        </Flickity>
      </div>
    </div>
  );
}
