import React from 'react';
import './messages.css';

export default function MessageContainer({ messages }) {
  const renderMessage = (message, idx, arr) => {
    const style = {};
    if (idx !== arr.length - 1) {
      const top = (arr.length - 1) * 55 - idx * 55;
      if (top > 220) {
        return null;
      }

      style.top = `${(arr.length - 1) * 55 - idx * 55}px`;
    }

    return (
      <li className="messageContainer" key={`message${idx}`} style={style}>
        <div className="messageImage">
          <img src={`/${message.image}.jpeg`} alt="logo" />
        </div>
        <div className="messageAuthor">{message.name}</div>
        <div className="messageResponse">{message.response}</div>
      </li>
    );
  };

  const displayMessages = (renderingMessages) => renderingMessages.map(renderMessage);

  return <ul id="mesasgesContainer">{displayMessages(messages)}</ul>;
}
