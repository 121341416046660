import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

export default function ViewDashboard() {
  const iframeRef = useRef(null);
  const { url } = useParams('');
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const adjustZoom = () => {
      const viewportWidth = document.documentElement.clientWidth;
      const viewportHeight = document.documentElement.clientHeight;

      // The charts are static sizing.
      const widthRatio = viewportWidth / 1100;
      const heightRatio = viewportHeight / 680;

      const zoomLevel = Math.min(widthRatio, heightRatio);

      setScale(zoomLevel * 100);
    };

    window.addEventListener('resize', adjustZoom);
    adjustZoom();

    return () => {
      window.removeEventListener('resize', adjustZoom);
    };
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        width: '1100px',
        height: '1680px',
        transformOrigin: 'top left',
        scale: `${scale}%`,
      }}
    >
      <iframe
        ref={iframeRef}
        src={url}
        style={{
          width: '1100px', height: '680px', border: 'none',
        }}
        title="dashview"
      />
    </div>
  );
}
