import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';

export const WebsocketContext = React.createContext({
  state: {},
  dispatchEvent: () => {},
});

export function WebsocketProvider(props) {
  const ws = useRef();
  const actionMap = useRef({});
  const [loading, setLoading] = useState(true);

  const connectDashboard = () => {
    ws.current.send(JSON.stringify({ action: 'dashboardConnect' }));
  };

  const connectExistingUser = (username) => {
    ws.current.send(JSON.stringify({ action: 'onReconnect', name: username }));
  };

  const connectNewUser = () => {
    ws.current.send(JSON.stringify({ action: 'onRequestName' }));
  };

  const setNewName = (name) => {
    ws.current.send(JSON.stringify({ action: 'onSetName', name }));
  };

  const onSelectWinner = (password) => {
    ws.current.send(JSON.stringify({ action: 'onSelectWinner', password }));
  };

  const addAction = (actionString, functionality) => {
    actionMap.current[actionString] = [
      ...(actionMap.current[actionString] || []),
      functionality,
    ];
  };

  const removeAction = (actionString, functionality) => {
    const actionArr = actionMap.current[actionString] || [];
    const newActionArr = actionArr.filter((fn) => fn !== functionality);

    actionMap.current[actionString] = newActionArr;
  };

  const onMessage = (evt) => {
    const data = JSON.parse(evt.data);
    const action = actionMap.current[data.action];

    if (action) {
      action.forEach((fn) => fn(data));
      return;
    }

    console.error(
      `Cannot find ${data.action} in actionMap. Have you registered it?`,
    );
  };

  useEffect(() => {
    const connectWs = () => {
      ws.current = new WebSocket(process.env.REACT_APP_WS_URL);
      ws.current.onmessage = onMessage;
      ws.current.onopen = () => {
        setLoading(false);
      };

      ws.current.onerror = () => {
        connectWs();
        const { disconnect } = actionMap;
        if (disconnect) {
          disconnect();
        }
      };

      ws.current.onclose = () => {
        connectWs();
        const { disconnect } = actionMap;
        if (disconnect) {
          disconnect();
        }
      };
    };

    connectWs();
    return () => {
      ws.current.close();
    };
  }, []);

  return (
    <WebsocketContext.Provider
      value={(useMemo(() => ({
        websocket: ws.current,
        connectDashboard,
        addAction,
        removeAction,
        connectExistingUser,
        connectNewUser,
        setNewName,
        onSelectWinner,
      }), []))}
    >
      {!loading && props.children}
    </WebsocketContext.Provider>
  );
}

export const useWs = () => React.useContext(WebsocketContext);
