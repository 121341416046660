import React, { useEffect, useState } from 'react';
import './question.css';
import { Card, Button, TextField } from '@mui/material';
import { useWs } from 'src/context/WebsocketContext';

// We have a winner selection page. We use this to decide the winner. It uses a basic password.
export default function WinnerSelectionPage() {
  const [password, setPassword] = useState('');
  const [winner, setWinner] = useState(null);
  const { onSelectWinner, addAction, removeAction } = useWs();

  useEffect(() => {
    const setWsWinner = (user) => setWinner(user.name);
    addAction('userWon', setWsWinner);

    return () => removeAction('userWon', setWsWinner);
  }, [addAction, removeAction]);

  const renderWinnerChoice = () => {
    const onChange = (evt) => {
      setPassword(evt.target.value);
    };

    const sendPassword = () => onSelectWinner(password);
    return (
      <div id="questionContainer">
        <Card variant="outlined" className="questionCard">
          <h1>What is the password?</h1>
          <TextField
            value={password}
            type="password"
            className="nameField"
            placeholder="Password"
            onChange={onChange}
          />
          <div className="buttonGroup">
            <Button
              className="submitButton"
              onClick={sendPassword}
              disabled={password.length === 0}
            >
              Submit
            </Button>
          </div>
        </Card>
      </div>
    );
  };

  const renderWinner = () => (
    <div id="questionContainer">
      <Card variant="outlined" className="questionCard winner">
        <h1>
          Winner:
          {winner}
        </h1>
      </Card>
    </div>
  );

  return (
    <div id="questions">
      <div id="questionsHeader">
        <img
          style={{ height: '45px', padding: '5px', marginTop: '10px' }}
          id="AWS"
          alt=""
          src="AWS.png"
        />
      </div>

      {(!winner && renderWinnerChoice()) || renderWinner()}
    </div>
  );
}
