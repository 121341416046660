import React, { useEffect, useState } from 'react';
import './question.css';
import { Card, Button, TextField } from '@mui/material';
import BadWords from 'bad-words';
import { useWs } from 'src/context/WebsocketContext';
import QuestionCard from 'src/components/QuestionCard';

export default function QuestionsPage() {
  const filter = new BadWords();
  const {
    setNewName,
    connectNewUser,
    connectExistingUser,
    addAction,
    removeAction,
  } = useWs();
  const [questions, setQuestions] = useState([]);
  const [image, setImage] = useState(null);
  const [username, setUsername] = useState('');
  const [winner, setWinner] = useState(null);
  const [selectedUsername, setSelectedUserName] = useState('');
  const [error, setError] = useState(null);

  const fetchQuestions = () => fetch(`${process.env.REACT_APP_API_DOMAIN}/questions`)
    .then((data) => data.json())
    .then((data) => {
      setQuestions(data.questions);
    });

  useEffect(() => {
    fetchQuestions();
    const storedUser = localStorage.getItem('username');
    const storedImage = localStorage.getItem('image') || 'sloth';

    if (!storedUser) {
      const setUserName = (newUser) => {
        localStorage.setItem('username', newUser.name);
        localStorage.setItem('image', newUser.image);
        setUsername(newUser.name);
        setImage(newUser.image);
      };

      const setNameError = (err) => {
        if (err.error === 'ConflictName') {
          setError(
            `The name ${err.usedName} has been used. Please select another name.`,
          );
        }
      };

      addAction('newlyConnected', setUserName);
      addAction('errorConnected', setNameError);
      return () => {
        removeAction('newlyConnected', setUserName);
        removeAction('errorConnected', setNameError);
      };
    }

    const setWsWinner = (user) => {
      setWinner(user);
    };
    addAction('userWon', setWsWinner);

    setImage(storedImage);
    setUsername(storedUser);
    connectExistingUser(storedUser);
    return () => {
      removeAction('userWon', setWsWinner);
    };
  }, [connectNewUser, connectExistingUser, addAction, removeAction]);

  const renderError = (err) => (
    <Card variant="outlined" className="errorCard">
      <h1>{err}</h1>
    </Card>
  );

  const renderNameChoice = () => {
    const onChange = (evt) => {
      setSelectedUserName(evt.target.value);
    };

    const iFeelLucky = () => connectNewUser();
    const setName = () => setNewName(selectedUsername);

    return (
      <div id="questionContainer">
        {error && renderError(error)}

        <Card variant="outlined" className="questionCard">
          <h1>What is your name?</h1>
          <TextField
            value={selectedUsername}
            className="nameField"
            placeholder="Your Name"
            onChange={onChange}
          />
          <div className="buttonGroup">
            <Button
              className="submitButton"
              onClick={setName}
              disabled={
                filter.isProfane(selectedUsername)
                && selectedUsername.length > 3
              }
            >
              Submit
            </Button>
            <Button className="submitButton" onClick={iFeelLucky}>
              Choose one for me
            </Button>
          </div>
        </Card>
      </div>
    );
  };

  const renderQuestions = () => questions.map((it) => (
    <QuestionCard key={it.id} question={it} username={username} />
  ));

  const renderWinner = () => (
    <div id="questionContainer">
      <Card variant="outlined" className="questionCard winner">
        {(winner.isWinner && (
          <h1>
            You have won! Show this to the Enterprise Booth:
            {' '}
            <br />
            {winner.name}
          </h1>
        )) || (
          <h1>
            Unfortunately you have not won this time, but thanks for
            participating!
          </h1>
        )}
      </Card>
    </div>
  );

  return (
    <div id="questions">
      <div id="questionsHeader">
        <img
          style={{ height: '45px', padding: '5px', marginTop: '10px' }}
          id="AWS"
          alt="AWS Logo"
          src="AWS.png"
        />
        {username && (
          <div id="loggedInUser">
            <li id="userCard">
              <div className="userImage">
                <img src={`${image}.jpeg`} alt="userLogo" />
              </div>
              <div className="userNamer">{username}</div>
            </li>
          </div>
        )}
      </div>

      {winner && renderWinner()}

      {username && !winner && (
        <div id="questionContainer">{renderQuestions()}</div>
      )}

      {!username && renderNameChoice()}
    </div>
  );
}
